import React, { useCallback } from 'react';
import { AiOutlinePrinter, AiOutlineShareAlt } from "react-icons/ai";
import { BsCodeSlash } from "react-icons/bs";
import { BiShare, BiX } from "react-icons/bi";
import './SidebarDetail.scss'
import { CatalogFeature } from './api/catalog';
import ReplaceTextToLink from './utils/ReplaceTextToLink';

const SingleFeatureTable: React.FC<{feature: CatalogFeature}> = ({feature}) => {
  const detailItems = Object.entries(feature.properties).filter(([key, _value]) => !key.startsWith('_viewer_'));

  detailItems.sort(([key1, _value1], [key2, _value2]) => {
    // make sure items with key=`class` and key=`subclass` are always on top
    if (key1 === 'class') return -1;
    if (key2 === 'class') return 1;
    if (key1 === 'subclass' && key2 !== 'class') return -1;
    if (key2 === 'subclass' && key1 !== 'class') return 1;

    return 0;
  });

  return (
    <table className='sidebar-detail-single-feature'>
      <tbody>
        { detailItems.map(([key, value]) => {
          return (
            key !== "" &&
            <tr className="sidebar-detail-item" key={key}>
              <th className='label'>{key}</th>
              <td className='content'><ReplaceTextToLink text={value} /></td>
            </tr>
          )
        })}
      </tbody>
    </table>
  );
};

type Props = {
  selected: CatalogFeature[]
  setSelected: React.Dispatch<React.SetStateAction<CatalogFeature[]>>
}

const Content: React.FC<Props> = (props) => {
  const { selected, setSelected } = props;

  const closeHandler = useCallback<React.MouseEventHandler>((event) => {
    event.preventDefault();
    setSelected([]);
  }, [setSelected]);

  const groupedFeatures: { [key: string]: CatalogFeature[] } = {};

  for (const feature of selected) {
    if (feature.catalog.type !== "DataItem") continue;
    const ary = groupedFeatures[feature.catalog.id] ||= [];
    ary.push(feature);
  }

  return (
    <div className='sidebar-detail'>
      <div className='inner'>
        <div className='close-btn-container'>
          <BiX className='close' onClick={closeHandler} />
        </div>
        <div className='sidebar-detail-inner'>
          { Object.entries(groupedFeatures).map(([id, features]) => (
            <div key={id} className='sidebar-detail-subsection'>
              <h2 className='category'>{features[0].catalog.name}</h2>
              <div>
                { features.map((feature, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        <div className='title'>
                          <h3>{ feature.properties["名称"] }</h3>
                        </div>
                        <div className='btnWrapper'>
                          <div className='btn'>
                            <div className='icon'>
                              <AiOutlineShareAlt />
                            </div>
                            <p className="btn-text">共有</p>
                          </div>
                          <div className='btn'>
                            <div className='icon'>
                              <AiOutlinePrinter />
                            </div>
                            <p className="btn-text">印刷</p>
                          </div>
                          <div className='btn'>
                            <div className='icon'>
                              <BsCodeSlash />
                            </div>
                            <p className="btn-text">埋め込む</p>
                          </div>
                          <div className='btn'>
                            <div className='icon'>
                              <BiShare />
                            </div>
                            <p className="btn-text">ここへ行く</p>
                          </div>
                        </div>
                        <SingleFeatureTable feature={feature} />
                        { idx < features.length - 1 && <hr /> }
                      </React.Fragment>
                    )
                  })
                }
              </div>
            </div>
          )) }
        </div>
      </div>
    </div>
  );
}

export default Content;
