import { useEffect, useState } from 'react';
import Sidebar from './Sidebar'
import MainMap from './MainMap'

// import type * as maplibregl from 'maplibre-gl';

import './App.scss';
import { useQuery } from 'react-query';
import { CatalogFeature, getCatalog } from './api/catalog';
import SidebarDetail from './SidebarDetail';
// import { useMediaQuery } from 'react-responsive'

function App() {
  const catalog = useQuery('catalog', getCatalog);
  const [selectedLayers, setSelectedLayers] = useState<string[]>([]);
  const [selectedFeatures, setSelectedFeatures] = useState<CatalogFeature[]>([]);

  // const isDesktop = useMediaQuery({ query: '(min-width: 769px)' })
  const [isOpenedSidebar, setIsOpenedSidebar] = useState<boolean>(true);

  const catalogSuccess = catalog.isSuccess;
  const catalogData = catalog.data;

  useEffect(() => {
    if (!catalogSuccess || !catalogData) {
      return;
    }
  }, [ catalogSuccess, catalogData, setSelectedLayers ]);


  return (
    <div className="App">
      <div className="container">
        <Sidebar
          catalogData={catalog.data || []}
          selectedLayers={selectedLayers}
          isOpenedSidebar={isOpenedSidebar}
          setIsOpenedSidebar={setIsOpenedSidebar}
          setSelectedLayers={setSelectedLayers}
          selectedFeatures={selectedFeatures}
          setSelectedFeatures={setSelectedFeatures}
          showDetail={selectedFeatures.length > 0}
        />
        { selectedFeatures.length > 0 &&
          <SidebarDetail
            selected={selectedFeatures}
            setSelected={setSelectedFeatures}
          />
        }
        <div className="map-container">
          <MainMap
            catalogData={catalog.data || []}
            selectedLayers={selectedLayers}
            setSelectedFeatures={setSelectedFeatures}
          />
        </div>
      </div>
    </div>
  );
}

export default App;
