import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import type * as maplibregl from 'maplibre-gl';
import { CatalogFeature, CatalogItem, walkCategories } from './api/catalog';
import { CustomStyle, customStyleToLineStringTemplate, customStyleToPointTemplate, customStyleToPolygonTemplate, DEFAULT_LINESTRING_STYLE, DEFAULT_POINT_STYLE, DEFAULT_POLYGON_STYLE, getCustomStyle, LayerTemplate, WEB_COLORS } from './utils/mapStyling';
import CityOS__Takamatsu from './cityos/cityos_takamatsu';
import type { Map as GeoloniaMap } from '@geolonia/embed';
import mapStyle from './style.json';

const CATALOG_DATA = [
  "DiaperChangingSpace",
  "Park",
  "ChildcareFacilities",
  "NursingSpaces",
  "KindergartensAndNurserySchools",
  "MedicalFacilities"
];


const LAYER_TEMPLATES: [string, (idx: number, customStyle?: CustomStyle[]) => LayerTemplate[]][] = [
  [ "Polygon", (i, customStyle) => {
    const color = WEB_COLORS[i * 1999 % WEB_COLORS.length];
    return customStyle ?
      customStyle.flatMap((style) => customStyleToPolygonTemplate(style, color)) :
      DEFAULT_POLYGON_STYLE(color);
  } ],
  [ "LineString", (i, customStyle) => {
    const color = WEB_COLORS[i * 1999 % WEB_COLORS.length];
    return customStyle ?
      customStyle.flatMap((style) => customStyleToLineStringTemplate(style, color)) :
      DEFAULT_LINESTRING_STYLE(color);
  }],
  [ "Point", (i, customStyle) => {
    const color = WEB_COLORS[i * 1999 % WEB_COLORS.length];
    return customStyle ?
      customStyle.flatMap((style) => customStyleToPointTemplate(style, color)) :
      DEFAULT_POINT_STYLE(color);
  }],
];

interface Props {
  catalogData: CatalogItem[];
  selectedLayers: string[];
  setSelectedFeatures: React.Dispatch<React.SetStateAction<CatalogFeature[]>>
}

const MainMap: React.FC<Props> = ({catalogData, selectedLayers, setSelectedFeatures}) => {
  const [map, setMap] = useState<GeoloniaMap | undefined>(undefined);
  const [cityOS] = useState<CityOS__Takamatsu | undefined>(undefined);
  const mapContainer = useRef<HTMLDivElement>(null);

  const catalogDataItems = useMemo(() => {
    return [...walkCategories(catalogData)];
  }, [catalogData]);

  useLayoutEffect(() => {
    if(mapContainer.current === null) return;
    const map = new window.geolonia.Map({
      container: mapContainer.current,
      // style: `${process.env.PUBLIC_URL}/style.json`,
      style: mapStyle as any,
      hash: true,
      center: [ 133.1397, 34.3975 ],
      fitBoundsOptions: { padding: 50 },
      // 意図せず傾き・回転を変更してしまうことを防ぐ
      maxPitch: 0,
      // @ts-ignore
      maxRotate: 0,
      minZoom: 9,
      zoom: 10.56,
    });

    (window as any)._mainMap = map;

    // const cityOS = new CityOS__Takamatsu(map as unknown as maplibregl.Map);
    // setCityOS(cityOS);

    map.on("load", () => {

      // todo：尾道用のデータ取得（尾道以外をネガティブに）

      // map.addSource('negative-city-mask', {
      //   type: 'vector',
      //   url: 'https://tileserver.geolonia.com/takamatsu_negative_mask/tiles.json?key=YOUR-API-KEY',
      // })
      // map.addLayer({
      //   id: 'negative-city-mask-layer',
      //   source: 'negative-city-mask',
      //   'source-layer': 'negativecitymask',
      //   type: 'fill',
      //   paint: {
      //     'fill-color': '#0079C4',
      //     'fill-opacity': .3,
      //   }
      // });
      // map.addLayer({
      //   id: 'negative-city-mask-layer-border',
      //   source: 'negative-city-mask',
      //   'source-layer': 'negativecitymask',
      //   type: 'line',
      //   paint: {
      //     'line-color': '#0079C4',
      //     'line-opacity': 0.5,
      //     'line-width': 2,
      //   }
      // })

      map.addSource('takamatsu', {
        type: 'vector',
        url: "https://tileserver.geolonia.com/takamatsu_main_v0/tiles.json?key=YOUR-API-KEY"
      });
      map.addSource('kihonzu', {
        type: 'vector',
        url: "https://tileserver.geolonia.com/takamatsu_kihonzu_v1/tiles.json?key=YOUR-API-KEY"
      });

      setMap(map);
    });


    map.on('click', (e) => {
      const features = map
        .queryRenderedFeatures(e.point)
        .filter(feature => (CATALOG_DATA.includes(feature.source)));
      if (features.length === 0) {
        setSelectedFeatures([]);
        return;
      }

      setSelectedFeatures(features.map(feature => {
        const catalogData = catalogDataItems.find(item => (
          item.type === "DataItem" && (item.liveLocationId === feature.source)
        ));
        if (!catalogData) {
          throw new Error(`Catalog data not available for feature: ${feature}`);
        }
        return {
          catalog: catalogData,
          properties: feature.properties,
        };
      }));

    });

    return () => {
      map.remove();
    };
  }, [catalogDataItems, mapContainer, setMap, setSelectedFeatures]);


  useEffect(() => {
    if (!map) return;

    let shouldStop = false;

    (async () => {
      let index = -1;
      for (const definition of walkCategories(catalogData)) {
        index += 1;
        if (shouldStop) return;

        const definitionId = definition.id;
        const isSelected = selectedLayers.includes(definitionId);

        if ("liveLocationId" in definition) {
          const sourceId = definition.liveLocationId;
          const drawType = definition.drawType;

          // サイドメニューでチェックボックスが選択されていたら
          if (isSelected) {
            // レイヤーが追加されてなかったら
            if(!map.getLayer(`${sourceId}-${drawType}`)) {

              let features = [];

              for (const item of definition.metadata) {
                features.push(item);
              }

              if (sourceId) {
                map.addSource(sourceId, {
                  'type': 'geojson',
                  'data': `${process.env.PUBLIC_URL}/${definition.metadata}`
                });

                map.addLayer({
                  id: `${sourceId}-${drawType}`,
                  type: "symbol",
                  layout: {
                    'icon-image': `${sourceId}`,
                    'icon-allow-overlap': true,
                    'icon-size': 0.45,
                  },
                  source: sourceId
                });

              }
            }else {
              map.setLayoutProperty(`${sourceId}-${drawType}`, 'visibility', 'visible');
            }
          } else {
            // 非表示にする
            map.setLayoutProperty(`${sourceId}-${drawType}`, 'visibility', 'none');
          }
          continue;
        }

        for (const [sublayerName, template] of LAYER_TEMPLATES) {
          const fullLayerName = `takamatsu/${definitionId}/${sublayerName}`;
          const mapLayers = map.getStyle().layers.filter((layer) => layer.id.startsWith(fullLayerName));
          const customStyle = getCustomStyle(definition);
          for (const subtemplate of template(index, customStyle)) {
            if (mapLayers.length === 0 && isSelected) {
              const filterExp: maplibregl.FilterSpecification = ["all", ["==", "$type", sublayerName]];
              // if (definition.class) {
              //   filterExp.push(["==", "class", definition.class]);
              // }
              if (subtemplate.filter) {
                filterExp.push(subtemplate.filter as any);
              }
              const layerConfig: maplibregl.LayerSpecification = {
                ...subtemplate,
                filter: filterExp,
                id: fullLayerName + subtemplate.id,
              };

              map.addLayer(layerConfig, 'poi');
              if (!map.getLayer(layerConfig.id)) {
                console.error(`Failed to add layer ${layerConfig.id}!!!`);
                debugger;
              }
            } else if (mapLayers.length > 0 && !isSelected) {
              for (const mapLayer of mapLayers) {
                map.removeLayer(mapLayer.id);
              }
            }
          }
        }
      }
    })();

    return () => {
      shouldStop = true;
    }
  }, [map, catalogData, selectedLayers, cityOS]);

  return (
    <div
      className='map'
      ref={mapContainer}
      data-lang="ja"
      data-navigation-control="on"
      data-gesture-handling="off"
    ></div>
  );
}

export default MainMap;
